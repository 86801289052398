// routing
import { Outlet } from 'react-router-dom';

const Feed = () => {
	return (
		<>
			<Outlet />
		</>
	);
};

export default Feed;