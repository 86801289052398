import { callGET, callPOST } from 'network/network';
import { FeedPost } from 'types/FeedPost.d';

const _FEED_POSTS_PATH = 'v1/feeds/';

export const getFeedPosts = async () => {
	try {
		const response = await callGET(_FEED_POSTS_PATH, true, {});
		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const addFeedPost = async (newPost: Partial<FeedPost>) => {
	try {
		const response = await callPOST(_FEED_POSTS_PATH, newPost, true, {});

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

