import { callDELETE, callGET, callPOST, callPUT } from 'network/network';
import { FeedPost } from 'types/FeedPost';

const _FEEDPOSTS_PATH = 'v1/feeds';
const _FEEDPOSTS_PICTURE_PATH = 'v1/feeds/{id}/image';
const _FEEDPOSTS_PDF_PATH = 'v1/feeds/{id}/pdf';

export const uploadPictureFile = async (postId: number, form: FormData, brandCode: string) => {
    try {
        const response = await callPUT(_FEEDPOSTS_PICTURE_PATH.replace(
			/{id}/g,
			postId.toString()
		  ), form, true, { brandCode: brandCode});

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const uploadPdfFile = async (postId: number, form: FormData, brandCode: string) => {
    try {
        const response = await callPUT(_FEEDPOSTS_PDF_PATH.replace(
			/{id}/g,
			postId.toString()
		  ), form, true, { brandCode: brandCode});

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const deletePictureFile = async (postId: number) => {
    try {
        const response = await callDELETE(_FEEDPOSTS_PICTURE_PATH.replace(
			/{id}/g,
			postId.toString()
		  ), {}, true, {});

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const deletePDFFile = async (postId: number) => {
    try {
        const response = await callDELETE(_FEEDPOSTS_PDF_PATH.replace(
			/{id}/g,
			postId.toString()
		  ), {}, true, {});

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getFeedPostFromId = async (feedpostId: number, brandCode: string) => {
	try {
		const response = await callGET(_FEEDPOSTS_PATH + '/' + feedpostId, true, { brandCode: brandCode });

		if (response === undefined || !response) {
			throw new Error();
		}

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const putFeedPost = async (updatedFeedPost: Partial<FeedPost>) => {
	try {
		const id = updatedFeedPost.id;
		if (!id) throw new Error('Id non presente');
		await callPUT(_FEEDPOSTS_PATH, updatedFeedPost, true, {});
	} catch (error: any) {
		throw error;
	}
};

export const deleteFeedPostFromId = async (feedpostId: number) => {
	try {
		const response = await callDELETE(_FEEDPOSTS_PATH + '/' + feedpostId, {}, true, {});

		return response;
	} catch (error: any) {
		throw error;
	}
};

export const publishFeedPost = async (feedpostId: number) => {
	try {
		const id = feedpostId;
		if (!id) throw new Error('Id non presente');
		await callPUT(_FEEDPOSTS_PATH + '/' + id + '/publish', {}, true, {});
	} catch (error: any) {
		throw error;
	}
};