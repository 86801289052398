import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//assets
import { ReactComponent as IsPinnedIconSVG} from './assets/isPinnedIcon.svg';
import { ReactComponent as IsPinnedIconSVGDisabled} from './assets/isPinnedOffIcon.svg';

//types
import { IsPinnedProps } from './IsPinned.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { FeedPost } from 'types/FeedPost';

//style
import { IsPinnedBox, IsPinnedIcon, PriceType, PriceTypeSpec } from './style/IsPinned.component.style';
import useHasRole from 'hooks/useHasRole';

const IsPinned: FC<IsPinnedProps> = ({ feedpost, putPost, disabled = false }) => {
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');

	const clickHandler = async () => { 
		if (!feedpost.id) return;
		const snackbarData: SnackbarOptions = {};
		try {
			await putPost({ id: feedpost.id, pinned: !feedpost.pinned } as Partial<FeedPost>);
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento messa in evidenza";
			dispatch(addSnackbar(snackbarData));
			console.log(error);
			if (!isMounted()) return;
		}
	};

	return (
		<IsPinnedBox>
			<IsPinnedIcon isPinned={feedpost.pinned} canToggle={true} onClick={clickHandler} title={"clicca per "+(feedpost.pinned ? "togliere Pinned" : "rendere Pinned")}>{feedpost.pinned ? <IsPinnedIconSVG /> : <IsPinnedIconSVGDisabled />}</IsPinnedIcon>
		</IsPinnedBox>
	);
};

export default IsPinned;
