import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useBrand from 'hooks/useBrand';

//types
import { VisibleProps } from './Visible.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//assets
import { ReactComponent as VisibleOnIcon } from './assets/visibleON.svg';
import { ReactComponent as VisibleOffIcon } from './assets/visibleOFF.svg';

//utils

//style
import { VisibleBox, VisibleIcon } from './style/Visible.component.style';
import useHasRole from 'hooks/useHasRole';

const Visible: FC<VisibleProps> = ({ postId, visible, putPost }) => {
	const brand = useBrand();

	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isFeed = useHasRole('FEED-'+brand?.code);

	const clickHandler = async () => {
		const snackbarData: SnackbarOptions = {};

		try {
			await putPost({ id: postId, visible: !visible });
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento visibilità";
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	return (
		<VisibleBox>
			<VisibleIcon
				title={(isSuperAdmin || isFeed ) ? (visible ? 'Nascondi' : 'Mostra') : ''}
				visible={visible}
				canToggle={isSuperAdmin || isFeed}
				onClick={(isSuperAdmin || isFeed) ? clickHandler : () => alert("Non hai i permessi per modificare la visibilità del post")}
			>
				{visible && <VisibleOnIcon />}
				{!visible && <VisibleOffIcon />}
			</VisibleIcon>
		</VisibleBox>
	);
};

export default Visible;
