export const staffRoles: string[] = [
	'SUPERADMIN',
	'ADMIN',
	'MINIADMIN',
	'AUTORE',
	'MARKETING',
	'PERSONAL TRAINER',
	'DIRETTA',
	'REGISTA-LDL',
	'REGISTA-MB',
	'REGISTA-TCR',
	'REGISTA-OMK',
	'EDITOR-LDL',
	'EDITOR-MB',
	'EDITOR-TCR',
	'EDITOR-OMK',
	'ADMIN AUTOSTRADA',
	'ASSISTENTE PERSONALE',
	'IMPRENDITORE',
	'COACH BONUS',
	'ROADMAP',
	'MAIL',
];

export const authorPoolsRoles: string[] = ['SUPERADMIN', 'ADMIN', 'AUTORE'];
