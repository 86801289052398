import { FC, useEffect, useMemo, useState } from 'react';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';
import useBrand from 'hooks/useBrand';

//types
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { FeedPost } from 'types/FeedPost';
import { TabSelectorItem, TabSelectorSettings } from 'components/TabSelector/TabSelector.component.d';


//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import FeedPostsTable from './FeedPostsTable/FeedPostsTable.component';
import TabSelector from 'components/TabSelector';

//utils
import { putFeedPost } from 'services/feedpost.services';
import { getFeedPosts } from './services/FeedPage.services';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';
import { ReactComponent as AddBoxIcon } from './assets/addBoxIcon.svg';
import { ReactComponent as VisibleON } from './assets/visibleON.svg';
import { ReactComponent as VisibleOFF } from './assets/visibleOFF.svg';


//style
import { 
	Page, 
	SearchFeedBox, 
	SearchInput, 
	SearchClearButton, 
	SearchButton, 
	AddPost,
	NotVisibleFilterButton,
	NotVisibleFilterInput,
} from './style/FeedPage.component.style';
import { BaseContentCard } from 'style/wrappers/wrappers';
import useAppDispatch from 'hooks/useAppDispatch';
// import { updatePost } from 'redux/actions/actions-global';
import AddFeedPostModal from './AddFeedPostModal';
import { enableAppMain } from 'redux/slices/slice-app-main';
import { constants } from 'http2';

const FeedPage: FC = () => {
    const brand = useBrand();

	const [searchInput, setSearchInput] = useState<string>('');
	const [searchInputValue, setSearchInputValue] = useState<string>('');
	const [originalFeedList, setOriginalFeedList] = useState<FeedPost[]>([]);
	const [localFeedList, setLocalFeedList] = useState<FeedPost[]>([]);
	const [tabSelectorValue, setTabSelectorValue] = useState<string>('posts');
	const [isAddPostModalVisible, setIsAddPostModalVisible] = useState(false);
	const [showHiddenFilter, setShowHiddenFilter] = useState<boolean>(false);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAuthor = useHasRole('AUTORE');
	const isFeed = useHasRole('FEED');


	const canCreatePost = isSuperAdmin || isFeed;

	const dispatch = useAppDispatch();


	const addPostClickHandler = () => {
		setIsAddPostModalVisible(true);
	};

	const closeAddPostModal = () => {
		setIsAddPostModalVisible(false);
		dispatch(enableAppMain());
	};

	const clearSearchHandler = () => {
		setSearchInput('');
		setSearchInputValue('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInputValue(searchValue.target.value);
	};

	const searchKeyHandler = (post: any) => {
		if (post.keyCode === 27) {
			clearSearchHandler();
		}
		if (post.keyCode === 13) {
			setSearchInput(post.target.value);
		}
	};

	const searchButtonHandler = () => {
		setSearchInput(searchInputValue);
	};

	const handleShowHiddenFilterClick = () => {
		setShowHiddenFilter((prevState) => !prevState);
	};

	const putUpdatePost = async (updatedPost: Partial<FeedPost>) => {
		if (!brand) return;
		try {
			await putFeedPost(updatedPost);
			const oldList: FeedPost[] | undefined = [ ...originalFeedList];
			if (!oldList) return;
			const newList: FeedPost[] = oldList.map((item: FeedPost) => {
				if (!updatedPost.id) return item;
				if (updatedPost.visible === undefined) return item;
				if (item.id === updatedPost.id) {
				  return { ...item, visible: updatedPost.visible };
				}
				return item;
			  });
			if (!newList) return;
			setOriginalFeedList(newList);
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento visibilità post";
			dispatch(addSnackbar(snackbarData));
		}
	};

	const filterFeedList = (unsorted: FeedPost[]): FeedPost[] => {
		if (!unsorted) return [];
		let filteredFeed = originalFeedList?.filter((p) => 
				!p.deleted &&
				p.title.toLowerCase().includes(searchInput.toLowerCase()) &&
				((showHiddenFilter && (p.visible === false)) || (p.visible === true))
			);
			if (filteredFeed) {
				filteredFeed.sort((a, b) => {
					return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime();
				});
				return filteredFeed;
			}
		return [];
	}

	//filter postList on feedPostType change
	useEffect(() => {
			if (!originalFeedList) return;
			let filteredFeed = filterFeedList(originalFeedList);
			setLocalFeedList(filteredFeed);
	}, [searchInput, showHiddenFilter, originalFeedList]);

	useEffect(() => {
		if (!brand) return;
		const gre = async () => {
			const response = await getFeedPosts();
			
			response.sort((a: any, b: any) => {
				if (a.tbd === true && b.tbd !== true) return -1;
				if (a.tbd !== true && b.tbd === true) return 1;
				return new Date(b.beginDate).getTime() - new Date(a.beginDate).getTime();
			});
			// setLocalFeedList(response);
			setOriginalFeedList(response);
		}
		gre();
	}, [brand]);

	const postsTable = useMemo(() => {
		if (localFeedList !== undefined)
			return (
				<FeedPostsTable
					data={localFeedList}
					putPost={putUpdatePost}
				/>
			);
	}, [localFeedList, putUpdatePost]);

	const loader = useMemo(() => {
		return (<div>Loading...</div>);
	}, []);

	const isSearchButtonVisible: boolean = searchInput !== '';
	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Gestione Feed' };

	if (!isSuperAdmin && !isFeed) return <></>;

	return (
		<>
			<Page>
				<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
					<NotVisibleFilterButton onClick={handleShowHiddenFilterClick}>
						<NotVisibleFilterInput
							id='showhiddenfilter'
							type={'checkbox'}
							checked={showHiddenFilter}
						/>
						<VisibleOFF /> Mostra nascosti
					</NotVisibleFilterButton>
					{(canCreatePost) && (
						<AddPost onClick={addPostClickHandler}>
							<AddBoxIcon /> Nuovo Post
						</AddPost>
					)}
					<SearchFeedBox>
						<SearchInput
							onKeyDown={searchKeyHandler}
							onChange={searchChangeHandler}
							value={searchInputValue}
						/>

						{isSearchButtonVisible && (
							<SearchClearButton onClick={clearSearchHandler}>
								<ClearButtonIconSVG />
							</SearchClearButton>
						)}

						<SearchButton onClick={searchButtonHandler}>
							<SearchIconSVG />
						</SearchButton>
					</SearchFeedBox>
				</DashboardTitleSection>

				<BaseContentCard>
					{localFeedList === undefined ? loader : postsTable}
				</BaseContentCard>
			</Page>
			{isAddPostModalVisible && <AddFeedPostModal elementType = {tabSelectorValue} onCloseButtonModal={closeAddPostModal} />}
		</>
	);
};

export default FeedPage;
