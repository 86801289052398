import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useHasRole from 'hooks/useHasRole';
import useBrand from 'hooks/useBrand';

//types
import { AddFeedPostModalProps } from './AddFeedPostModal.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { FeedPost } from 'types/FeedPost';


//components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

//utils
import { addFeedPost } from '../services/FeedPage.services';

//style
import { CardContent, CloseButton, IconBox, PostTitle, TextContent, TextDisclaimer, Title } from './style/AddFeedPostModal.component.style';
import { useNavigate } from 'react-router-dom';
import { enableAppMain } from 'redux/slices/slice-app-main';


const AddPostModal: FC<AddFeedPostModalProps> = ({ elementType, onCloseButtonModal }) => {
	const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
	const [postName, setPostName] = useState('');
	const [isAddEnabled, setIsAddEnabled] = useState(false);

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');
	const isAuthor = useHasRole('AUTORE');
	const isAnimator = useHasRole('ANIMATORE');
	const isFeed = useHasRole('FEED'); // IT SHOULD CHECK FOR ALL BRANDS

	const canCreateFeed = isSuperAdmin || isFeed; // WE ARE NOT USING IT

	const element = elementType === 'types' ? "il tipo post" : "ilpost";

	const brand = useBrand();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const closeButtonHandler = () => {
		if (onCloseButtonModal) onCloseButtonModal();
		dispatch(enableAppMain());
	};

	const onPostNameChangeHandler = (post: React.ChangeEvent<HTMLInputElement>) => {
		setPostName(post.currentTarget.value);
	};

	const addPostHandler = () => {
		if (window.confirm(`Aggiungere ${element} col nome "${postName}" ?`)) {
			if (elementType !== 'types') callAddPost();
		}
	};

	function capitalizeFirstLetter(lowstring: string) {
		return lowstring.charAt(0).toUpperCase() + lowstring.slice(1);
	}

	const callAddPost = async () => {
		if (!brand) return;
		const snackbarData: SnackbarOptions = {};
		const newPost: Partial<FeedPost> = { title: postName, visible: true, deleted: false, pinned: false };
		try {
			const response = await addFeedPost(newPost);
			console.log(response);

			snackbarData.type = 'success';
			snackbarData.message = capitalizeFirstLetter(`${element} è stato aggiunto correttamente`);
			await dispatch(addSnackbar(snackbarData));
			if (onCloseButtonModal) onCloseButtonModal();
			// console.log('reservedArea/feed/' + response.id);

			navigate('/reservedArea/feed/' + response.id);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = `Non è stato possibile aggiungere ${element}.`;
			dispatch(addSnackbar(snackbarData));
		}
	};

	useEffect(() => {
		if (postName?.length > 0) setIsAddEnabled(true); else setIsAddEnabled(false);
	}, [postName]);

	return (
		<BaseModal
			isBackdropClickActive={true}
			onClose={closeButtonHandler}
			isCloseButtonClicked={isCloseButtonClicked}
		>
			<BaseCard
				flavor='outlined'
				maxWidth='700px'
			>
				<CardContent>
					<PostTitle>
						<label>Titolo:</label>
						<input
							type='text'
							size={50}
							value={postName}
							onChange={onPostNameChangeHandler}
						></input>
					</PostTitle>
					<button
						disabled={!isAddEnabled}
						onClick={addPostHandler}
					>
						Aggiungi {element}
					</button>
				</CardContent>
			</BaseCard>
		</BaseModal>
	);
};

export default AddPostModal;
