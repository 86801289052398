import { ReactElement } from 'react';

// pages
// import LandingPage from 'pages/LandingPage';
import LoginPage from 'pages/LoginPage';
import LoginForm from 'pages/LoginPage/LoginForm';
import PasswordRecoveryForm from 'pages/LoginPage/PasswordRecoveryForm';
import PasswordChangeForm from 'pages/LoginPage/PasswordChangeForm';
import BackOfficePage from 'pages/BackOfficePage';
import { Navigate } from 'react-router-dom';
import ReservedArea from 'pages/ReservedArea/ReservedArea';
import SendNotificationsPage from 'pages/SendNotificationsPage/SendNotificationsPage.component';
import APLessonsPage from 'pages/APLessonsPage';
import MyLessonsPage from 'pages/MyLessonsPage';
import HighwayRoutesPage from 'pages/HighwayRoutesPage';
import PaymentsPage from 'pages/PaymentsPage';
import OldPaymentsPage from 'pages/OldPaymentsPage';
import AuthorServicesManagementPage from 'pages/AuthorServicesManagementPage';
import AuthorDisabledManagementPage from 'pages/AuthorDisabledManagementPage';
import UsersPage from 'pages/UsersPage';
import UserDetailPage from 'pages/UserDetailPage';
import Users from 'pages/UsersPage/Users';
import ProductsPage from 'pages/ProductsPage/ProductsPage.component';
import Products from 'pages/ProductsPage/Products';
import ProductDetailPage from 'pages/ProductDetailPage';
import Levels from 'pages/LevelsPage/Levels';
import LevelsPage from 'pages/LevelsPage/LevelsPage.component';
import LevelDetailPage from 'pages/LevelDetailPage';
import Points from 'pages/PointsPage/Points';
import PointsPage from 'pages/PointsPage';
import AdministrationPage from 'pages/AdministrationPage';
import WhatsappPage from 'pages/WhatsappPage';
import RoadmapPage from 'pages/RoadmapPage';
import Roadmap from 'pages/RoadmapPage/Roadmap';
import ActiveInactivePage from 'pages/ActiveInactivePage';
import TestimonialsPage from 'pages/TestimonialsPage/TestimonialsPage.component';
import RoadmapEventDetailPage from 'pages/RoadmapEventDetailPage/RoadmapEventDetailPage.component';
import RoadmapEventTypeDetailPage from 'pages/RoadmapEventTypeDetailPage/RoadmapEventTypeDetailPage.component';
import FeedPage from 'pages/FeedPage';
import Feed from 'pages/FeedPage/Feed';
import FeedPostDetailPage from 'pages/FeedPostDetailPage/FeedPostDetailPage.component';

//utils
import { putProduct } from 'services/product.services';
import { putLevel } from 'services/level.services';
import { putRoadmapEvent } from 'services/roadmapevent.services';
import ShopPage from 'pages/ShopPage';


export interface AppRoute {
	name: string;
	path: string;
	element: ReactElement;
	isIndex?: boolean | undefined;
	isProtected?: boolean | undefined;
	subRoutes?: AppRoute[];
}

/**
 * first item will always be root,
 * last item will always be "not found page"
 */
const appRoutes: AppRoute[] = [
	{
		name: 'root',
		path: '/',
		element: <LoginPage />,
		subRoutes: [
			{
				name: 'loginForm',
				path: '/',
				element: <LoginForm />,
				isIndex: true,
			},
		],
	},
	{
		name: 'login',
		path: '/login',
		element: <LoginPage />,
		subRoutes: [
			{
				name: 'loginPasswordRecovery',
				path: 'password-recovery',
				element: <PasswordRecoveryForm />,
			},
			{
				name: 'loginChangePassword',
				path: 'change-password',
				element: <PasswordChangeForm />,
			},
			{
				name: 'loginForm',
				path: '/',
				element: <LoginForm />,
				isIndex: true,
			},
		],
	},
	{
		name: 'reservedArea',
		path: '/reservedArea',
		isProtected: true,
		element: <ReservedArea />,
		subRoutes: [
			{
				name: 'backoffice',
				path: '/',
				element: <BackOfficePage />,
				isProtected: true,
				isIndex: true,
			},
			{
				name: 'sendNotifications',
				path: 'sendNotifications',
				element: <SendNotificationsPage />,
				isProtected: true,
			},
			{
				name: 'activeinactive',
				path: 'activeinactive',
				element: <ActiveInactivePage />,
				isProtected: true,
			},
			{
				name: 'APLessons',
				path: 'APLessons',
				element: <APLessonsPage />,
				isProtected: true,
			},
			{
				name: 'myLessons',
				path: 'mylessons',
				element: <MyLessonsPage />,
				isProtected: true,
			},
			{
				name: 'highwayroutes',
				path: 'highwayroutes',
				element: <HighwayRoutesPage />,
				isProtected: true,
			},
			{
				name: 'payments',
				path: 'payments',
				element: <PaymentsPage />,
				isProtected: true,
			},
			{
				name: 'oldpayments',
				path: 'oldpayments',
				element: <OldPaymentsPage />,
				isProtected: true,
			},
			{
				name: 'authorservicesmanagement',
				path: 'authorservicesmanagement',
				element: <AuthorServicesManagementPage />,
				isProtected: true,
			},
			{
				name: 'authordisabledmanagement',
				path: 'authordisabledmanagement',
				element: <AuthorDisabledManagementPage />,
				isProtected: true,
			},
			{
				name: 'users',
				path: 'users',
				element: <Users />,
				isProtected: true,
				subRoutes: [
					{
						name: 'users',
						path: '/',
						element: <UsersPage />,
						isProtected: true,
						isIndex: true,
					},
					{
						name: 'userDetail',
						path: ':userId',
						element: <UserDetailPage />,
						isProtected: true,
					},
				],
			},
			{
				name: 'products',
				path: 'products',
				element: <Products />,
				isProtected: true,
				subRoutes: [
					{
						name: 'products',
						path: '/',
						element: <ProductsPage />,
						isProtected: true,
						isIndex: true,
					},
					{
						name: 'productDetail',
						path: ':productId',
						element: <ProductDetailPage putProduct={putProduct} />,
						isProtected: true,
					},
				],
			},
			{
				name: 'levels',
				path: 'levels',
				element: <Levels />,
				isProtected: true,
				subRoutes: [
					{
						name: 'levels',
						path: '/',
						element: <LevelsPage />,
						isProtected: true,
						isIndex: true,
					},
					{
						name: 'levelDetail',
						path: ':levelId',
						element: <LevelDetailPage putLevel={putLevel} />,
						isProtected: true,
					},
				],
			},
			{
				name: 'points',
				path: 'points',
				element: <Points />,
				isProtected: true,
				subRoutes: [
					{
						name: 'points',
						path: '/',
						element: <PointsPage />,
						isProtected: true,
						isIndex: true,
					},
				],
			},
			{
				name: 'administration',
				path: 'administration',
				element: <AdministrationPage />,
				isProtected: true,
			},
			{
				name: 'shop',
				path: 'shop',
				element: <ShopPage />,
				isProtected: true,
			},
			{
				name: 'testimonials',
				path: 'testimonials',
				element: <TestimonialsPage />,
				isProtected: true,
			},
			{
				name: 'whatsapp',
				path: 'whatsapp',
				element: <WhatsappPage />,
				isProtected: true,
			},
			{
				name: 'roadmap',
				path: 'roadmap',
				element: <Roadmap />,
				isProtected: true,
				subRoutes: [
					{
						name: 'roadmap',
						path: '/',
						element: <RoadmapPage />,
						isProtected: true,
						isIndex: true,
					},
					{
						name: 'roadmapEventDetail',
						path: ':eventId',
						element: <RoadmapEventDetailPage />,
						isProtected: true,
					},
					{
						name: 'roadmapEventTypeDetail',
						path: 'type/:eventTypeId',
						element: <RoadmapEventTypeDetailPage />,
						isProtected: true,
					},
				],
			},
			{
				name: 'feed',
				path: 'feed',
				element: <Feed />,
				isProtected: true,
				subRoutes: [
					{
						name: 'feed',
						path: '/',
						element: <FeedPage />,
						isProtected: true,
						isIndex: true,
					},
					{
						name: 'feedPostDetail',
						path: ':postId',
						element: <FeedPostDetailPage />,
						isProtected: true,
					},
				],
			},
		],
	},
	{
		name: 'default',
		path: '*',
		element: <Navigate to={'/login/'} />,
	},
];

export default appRoutes;
