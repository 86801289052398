import { FC, useState, useEffect, ChangeEvent, useRef } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import { useParams, useSearchParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';
import useBrand from 'hooks/useBrand';

//types
import { FeedPostDetailPageProps } from './FeedPostDetailPage.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { FeedPost } from 'types/FeedPost';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//assets
import { ReactComponent as EditIconSVG } from './assets/editIcon.svg';
import { ReactComponent as PDFIconSVG } from './assets/PDFicon.svg';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import EditableInput from 'components/EditableComponents/EditableInput';
import CheckboxInput from 'components/ui/CheckboxInput';
import Visible from './Visible';
import IsPinned from './IsPinned';

//utils
import { getFeedPostFromId, putFeedPost, deleteFeedPostFromId, uploadPictureFile, publishFeedPost, uploadPdfFile, deletePDFFile } from 'services/feedpost.services';
import isEqual from 'lodash/isEqual';

//tools
import { CKEditor } from 'ckeditor4-react';

//style
import { Row, Col } from 'style/layout';
import { 
	Page, 
	BaseContentCard, 
	InfoLabel, 
	EditableArea, 
	SubscriptionLabel, 
	SubscriptionMaxDate, 
	HoursDiv, 
	HoursSelect, 
	ProductImage, 
	ProductImageContainer, 
	ProductImageNewFile, 
	ImageEditIconOverlay, 
	PicButton,
	ProductPDFContainer,
	ProductPDF,
	PDFEditIconOverlay,
	PDFButton,
	ProductPDFNewFile,
	ImageName,
	PDFName
} from './style/FeedPostDetailPage.component.style';

const FeedPostDetailPage: FC<FeedPostDetailPageProps> = () => {
	const [feedpost, setFeedPost] = useState<FeedPost>({} as FeedPost);
	const [subPath, setSubPath] = useState<string | undefined>('');
	const [localLiveDate, setLocalLiveDate] = useState<Date>();
	const [localLiveHour, setLocalLiveHour] = useState(-1);
	const [localLiveMinute, setLocalLiveMinute] = useState(-1);
	const [localAbsoluteLiveDate, setLocalAbsoluteLiveDate] = useState('');
	const [dateIsWithoutTime, setDateIsWithoutTime] = useState<boolean>(true);
	const [imageIsClickable, setImageIsClickable] = useState<boolean>(false);
	const [PDFIsClickable, setPDFIsClickable] = useState<boolean>(false);
	const [localTemplateText, setLocalTemplateText] = useState<string>('');

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasChanged, setHasChanged] = useState<boolean>(false);

	const brand = useBrand();

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isFeed = useHasRole('FEED-'+brand?.code);

	const productImageNewFileInput = useRef<HTMLInputElement>(null);
	const productPdfNewFileInput = useRef<HTMLInputElement>(null);

	const feedpostsList: FeedPost[] = [];


	const { postId } = useParams();
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();
	const numericId: number = postId ? +postId : 0;

	const isValidDate = (dateToCheck: Date | string): boolean => {
		if (!dateToCheck) return false;
		try {
			const testDate = new Date(dateToCheck);
			return true;
		} catch (err) {
			return false;
		}
	};

	const convertDate = (dateToConvert: string): string => {
		const [parcialDate, parcialTime] = dateToConvert.split(", ");
		const [dday, dmonth, dyear] = parcialDate.split("/");
		const [dhours, dminutes, desconds] = parcialTime.split(":");
		const convertedDate = `${dyear}-${dmonth.padStart(2, "0")}-${dday.padStart(2, "0")}T${dhours.padStart(2, "0")}:${dminutes}:${desconds}`;
		return convertedDate;
	}

	const getFeedPost = async (numericId: number) => {
		if (!brand) return;
		if (numericId === 0) return;
		const snackbarData: SnackbarOptions = {};

		try {
			const response = await getFeedPostFromId(numericId, brand.code);
			setFeedPost(response);
			if (response.beginDate && new Date(response.beginDate).getHours()>=0 && new Date(response.beginDate).getHours()<=3) setDateIsWithoutTime(true)
			setIsLoading(false);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca degli posti';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};


	const publishPost = async () => {
		if (!brand) return;
		if (!feedpost.id) return;
		try {
			await publishFeedPost(feedpost.id);
			setHasChanged(false);
			setFeedPost({...feedpost, status: 'PUBLISHED'});
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'success';
			snackbarData.message = "Post pubblicato con successo";
			dispatch(addSnackbar(snackbarData));
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = "Errore nella pubblicazione del post";
			dispatch(addSnackbar(snackbarData));
		}
	}

	const deletePost = async () => {
		if (!brand) return;
		if (!feedpost.id) return;
		if (window.confirm(`Sei sicuro di voler cancellare il post? Questa operazione è irreversibile.`)) {
			try {
				await deleteFeedPostFromId(feedpost.id);
				setHasChanged(false);
				setFeedPost({...feedpost, deleted: true});
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = "Post cancellato con successo";
				dispatch(addSnackbar(snackbarData));
			} catch (error) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = "Errore nella cancellazione del post";
				dispatch(addSnackbar(snackbarData));
			}
		}
	}

	const putUpdatePost = async (updatedPost: Partial<FeedPost>) => {
		if (!brand) return;
		if (!updatedPost.id) return; 
		try {
			await putFeedPost(updatedPost);
			if (typeof updatedPost.visible !== 'undefined') setFeedPost({...feedpost, visible: updatedPost.visible});
			if (typeof updatedPost.pinned !== 'undefined') setFeedPost({...feedpost, pinned: updatedPost.pinned});
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento visibilità post";
			dispatch(addSnackbar(snackbarData));
		}
	};

	const saveFeedPost = async () => {
		if (!brand) return;
		const snackbarData: SnackbarOptions = {};
		if (feedpost.title.trim() === '') {
			snackbarData.type = 'error';
			snackbarData.message = "Devi specificare un nome per poter salvare.";
			dispatch(addSnackbar(snackbarData));
			return;
		}

		try {
			var localPost: FeedPost = feedpost;
			if (localLiveDate) {
				var subDate: Date = localLiveDate;
				if (dateIsWithoutTime) {
					subDate.setHours(1);
					subDate.setMinutes(0);
				} else {
					subDate.setHours(localLiveHour);
					subDate.setMinutes(localLiveMinute);
				}
				subDate.setSeconds(0);
				localPost.publishDate = subDate.toISOString();
			}
			await putFeedPost(localPost);
			setHasChanged(false);
			snackbarData.type = 'success';
			snackbarData.message = "Post aggiornato con successo";
			dispatch(addSnackbar(snackbarData));
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nel salvataggio del post";
			dispatch(addSnackbar(snackbarData));
		}
	};

	const saveLocalFeedPost = (inputId: string, value: string) => {
		var localPost: FeedPost = { ...feedpost };
		if (inputId === 'title') localPost.title = value;
		if (inputId === 'templateText') localPost.templateText = value;
		if (inputId === 'link') localPost.genericLink = value;
		if (inputId === 'youtubeLink') {
			if (value && !value.startsWith('https://')) {
				const snackbarData: SnackbarOptions = {
					type: 'error',
					message: 'Il link YouTube deve iniziare con https://'
				};
				dispatch(addSnackbar(snackbarData));
				return;
			}
			if (value && !value.toLowerCase().includes('youtu')) {
				const snackbarData: SnackbarOptions = {
					type: 'error',
					message: 'Il link inserito non sembra essere un link YouTube valido'
				};
				dispatch(addSnackbar(snackbarData));
				return;
			}
			localPost.youtubeLink = value;
		}
		if (!isEqual(localPost, feedpost)) setHasChanged(true);
		setFeedPost(localPost);
	}

	const handleOnChangeTemplateText = (post: ChangeEvent<HTMLTextAreaElement>) => {
		var localPost: FeedPost = { ...feedpost };
		localPost.templateText = post.target.value;
		if (!isEqual(localPost, feedpost)) setHasChanged(true);
		setFeedPost(localPost);
	};

	const onLiveDateChangeHandler = (post: React.ChangeEvent<HTMLInputElement>) => { 
		if (!isValidDate(post.currentTarget.value)) return;
		var subDate = new Date(post.currentTarget.value);
		var year = subDate.getFullYear().toString();
		const month = (subDate.getMonth() + 1).toString().padStart(2, '0'); 
		const day = subDate.getDate().toString().padStart(2, '0');
		const isoDate = `${year.padEnd(4,'0')}-${month}-${day}`;
		setLocalLiveDate(subDate);
		setLocalAbsoluteLiveDate(isoDate);
		setHasChanged(true);
	};

	// const onLiveHourChangeHandler = (post: React.ChangeEvent<HTMLSelectElement>) => { 
	// 	setLocalLiveHour(Number(post.currentTarget.value));
	// 	if ((!localLiveDate) || (!isValidDate(localLiveDate))) return;
	// 	const subDate = new Date(localLiveDate);
	// 	subDate.setHours(Number(post.currentTarget.value));
	// 	subDate.setMinutes(localLiveMinute);
	// 	subDate.setSeconds(0);
	// 	setHasChanged(true);
	// };

	// const onLiveMinuteChangeHandler = (post: React.ChangeEvent<HTMLSelectElement>) => { 
	// 	setLocalLiveMinute(Number(post.currentTarget.value));
	// 	if ((!localLiveDate) || (!isValidDate(localLiveDate))) return;
	// 	const subDate = new Date(localLiveDate);
	// 	subDate.setHours(localLiveHour);
	// 	if (Number(post.currentTarget.value) >= 0) subDate.setMinutes(Number(post.currentTarget.value));
	// 	subDate.setSeconds(0);
	// 	if (Number(post.currentTarget.value) < 0) subDate.setSeconds(1);
	// 	setHasChanged(true);
	// };

	// const handleOnChangeDateIsWithoutTime = (nowIsChecked: boolean) => {
	// 	setDateIsWithoutTime(nowIsChecked);
	// 	setHasChanged(true);
	// }

	const uploadPictureB64 = async (productId: number, fileB64: string, fileName: string): Promise<any | null> => {
		if (!brand) return;
		if (typeof fileB64 === 'undefined') return null;
		if (fileB64.length === 0) return null;
		var uploaded: any;
		await fetch(fileB64)
			.then((res) => res.blob())
			.then(async (blob) => {
				var formData = new FormData();
				const file = new File([blob], fileName, { type: blob.type });
				formData.append('file', file);
				uploaded = await uploadPictureFile(productId, formData, brand.code);
			});
		return uploaded;
	};

	const uploadPDFB64 = async (productId: number, fileB64: string, fileName: string): Promise<any | null> => {
		if (!brand) return;
		if (typeof fileB64 === 'undefined') return null;
		if (fileB64.length === 0) return null;
		var uploaded: any;
		await fetch(fileB64)
			.then((res) => res.blob())
			.then(async (blob) => {
				var formData = new FormData();
				const file = new File([blob], fileName, { type: blob.type });
				formData.append('file', file);
				uploaded = await uploadPdfFile(productId, formData, brand.code);
			});
		return uploaded;
	};

	const getFileContentInBase64 = (files: File[]) => {
		const fileReadPromises = files.map((file) => {
			return new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result as string);
				};
				reader.onerror = (error) => {
					reject(error);
				};
				reader.readAsDataURL(file);
			});
		});
		return Promise.all(fileReadPromises);
	};

	const handleSingleFilePictureChange = async (post: React.ChangeEvent<HTMLInputElement>) => {
		const files = post.target.files;
		if (files && files.length > 0) {
			const fileB64: string[] = await getFileContentInBase64([files[0]]);
			var uploaded = await uploadPictureB64(feedpost.id, fileB64[0], files[0].name);
			if (uploaded) {
				var localPost: FeedPost = { ...feedpost };
				localPost.image = uploaded;
				setFeedPost(localPost);
			}
		}
	};

	const handleSingleFilePDFChange = async (post: React.ChangeEvent<HTMLInputElement>) => {
		const files = post.target.files;
		if (files && files.length > 0) {
			const fileB64: string[] = await getFileContentInBase64([files[0]]);
			var uploaded = await uploadPDFB64(feedpost.id, fileB64[0], files[0].name);
			if (uploaded) {
				var localPost: FeedPost = { ...feedpost };
				localPost.pdf = uploaded;
				setFeedPost(localPost);
			}
		}
	};
	const onImageOver = () => {
		setImageIsClickable(true);
	};

	const onImageOut = () => {
		setImageIsClickable(false);
	};

	const onImageClick = (bypass?: Boolean) => {
		if (feedpost.deleted) return;
		if (imageIsClickable || bypass) {
			if (productImageNewFileInput.current) {
				productImageNewFileInput.current.click();
			}
		}
	};

	const onRemoveImageClick = async () => {
		if (!brand) return;
		if (feedpost.deleted) return;
		if (window.confirm(`Rimuovere l'immagine del post?`)) {
			try {
				await deletePDFFile(feedpost.id);
				var localPost: FeedPost = { ...feedpost};
				localPost.image = null;
				setFeedPost(localPost);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = "Errore durante la rimozione dell'immagine";
				dispatch(addSnackbar(snackbarData));
			}
		}
	}


	const onPDFOver = () => {
		setPDFIsClickable(true);
	};

	const onPDFOut = () => {
		setPDFIsClickable(false);
	};

	const onPDFClick = (bypass?: Boolean) => {
		if (feedpost.deleted) return;
		if (PDFIsClickable || bypass) {
			if (productPdfNewFileInput.current) {
				productPdfNewFileInput.current.click();
			}
		}
	};

	const onRemovePDFClick = async () => {
		if (!brand) return;
		if (feedpost.deleted) return;
		if (window.confirm(`Rimuovere il PDF del post?`)) {
			try {
				await deletePDFFile(feedpost.id);
				var localPost: FeedPost = { ...feedpost};
				localPost.pdf = null;
				setFeedPost(localPost);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = "Errore durante la rimozione del PDF";
				dispatch(addSnackbar(snackbarData));
			}
		}
	}

	useEffect(() => {
		if (!brand) return;
		getFeedPost(numericId);
	}, [numericId, brand]);

	useEffect(() => {
		if (!isLoading) setSubPath((subPath) => 'Post / ' + postId?.toString() + ' | ' + feedpost?.title);
	}, [isLoading, feedpost, postId]);

	useEffect(() => {
		if (feedpost?.publishDate) {
			const fixedDate = ((typeof feedpost?.publishDate === "string") && (feedpost?.publishDate.slice(-1) === 'Z')) ? feedpost?.publishDate : feedpost?.publishDate+'Z';
			const utcDate = new Date(fixedDate);
			const year = utcDate.getFullYear();
			const month = (utcDate.getMonth() + 1).toString().padStart(2, '0');
			const day = utcDate.getDate().toString().padStart(2, '0');
			const isoDate = `${year}-${month}-${day}`;
			const onlyDate = new Date(isoDate);

			if (!isValidDate(utcDate)) return;
			const localDatetimeString = convertDate(utcDate.toLocaleString());
			setLocalLiveDate(new Date(onlyDate));
			setLocalAbsoluteLiveDate(isoDate);
			setLocalLiveHour((new Date(localDatetimeString)).getHours());
			setLocalLiveMinute((new Date(localDatetimeString)).getMinutes());
		} else {
			const today = new Date();
			const year = today.getFullYear();
			const month = (today.getMonth() + 1).toString().padStart(2, '0');
			const day = today.getDate().toString().padStart(2, '0');
			const isoDate = `${year}-${month}-${day}`;
			
			setLocalLiveDate(today);
			setLocalAbsoluteLiveDate(isoDate);
		}
	}, [feedpost?.publishDate]);

	const breadCrumbSettings: TitleBreadCrumbSettings = {
		subSectionText: 'Gestione Feed',
		subSubSectionText: subPath,
		subSectionLink: '/reservedArea/feed',
	};

	if (!isSuperAdmin && !isFeed) return (<></>);

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>

			{isLoading && <>Loader</>}
			{!isLoading && (
			<BaseContentCard>
				<Row>
					<Col width="100%" margin="20px">
						<Row>
							<Visible
								postId={feedpost.id}
								visible={feedpost.visible}
								putPost={putUpdatePost}
							/>
							<IsPinned
								feedpost={feedpost}
								putPost={putUpdatePost}
							/>
						</Row>
						<Row>
							<InfoLabel>Titolo:</InfoLabel>

							<EditableInput
								inputId={'title'}
								editableInput={feedpost.title}
								update={saveLocalFeedPost}
								placeholder='Inserisci il titolo'
							/>
						</Row>
						<Row>
							<InfoLabel>Testo:</InfoLabel>

							{/* <EditableArea
								value={feedpost.templateText}
								onChange={handleOnChangeTemplateText}
							/> */}

							<CKEditor
								onChange={(evt) => {
									const newText = evt.editor.getData();
									setLocalTemplateText(newText);
									
									setFeedPost(prevFeedpost => {
										const localPost = { ...prevFeedpost, templateText: newText };
										if (newText !== prevFeedpost.templateText) {
											setHasChanged(true);
										}
										return localPost;
									});
								}}
								initData={feedpost.templateText}
								onInstanceReady={(editor) => {}}
								config={{
									language: 'it',
									height: '300px',
									removeButtons: 'Image',
									removePlugins: 'resize',
									extraPlugins: 'emoji',
								}}
							/>
						</Row>
						<Row>
							<InfoLabel>Link:</InfoLabel>

							<EditableInput
								inputId={'link'}
								editableInput={feedpost.genericLink}
								update={saveLocalFeedPost}
								placeholder='Incolla il link'
							/>
						</Row>

						<Row>
							<InfoLabel>Link YouTube:</InfoLabel>

							<EditableInput
								inputId={'youtubeLink'}
								editableInput={feedpost.youtubeLink}
								update={saveLocalFeedPost}
								placeholder='Incolla il link YouTube'
							/>
						</Row>

					{/* </Col>
					<Col width="50%" margin="20px"> */}
						
						<Row>
							<SubscriptionLabel>Data:</SubscriptionLabel>
							<SubscriptionMaxDate
								type='date'
								// value={(localLiveDate && isValidDate(localLiveDate)) ? localLiveDate?.toISOString().substring(0, 10) : ''}
								value={localAbsoluteLiveDate}
								onChange={onLiveDateChangeHandler}
							/>
							{/* <Col><Row>
							<select id="hourSelect" onChange={onLiveHourChangeHandler} disabled={dateIsWithoutTime}>
								<option key={-1} value={-1}>--</option>
								{Array.from({ length: 24 }, (_, index) => index).map((hour) => (
								<option 
									key={hour} 
									value={hour}
									selected={(localLiveDate && isValidDate(localLiveDate) && (localLiveHour === hour)) ? true : false }
								>
									{hour < 10 ? `0${hour}` : hour}
								</option>
								))}
							</select>
							<HoursDiv>:</HoursDiv>
							<select id="minuteSelect" onChange={onLiveMinuteChangeHandler} disabled={dateIsWithoutTime}>
								<option key={-1} value={-1}>--</option>
								{Array.from({ length: 4 }, (_, index) => index * 15).map((minute) => (
								<option 
									key={minute} 
									value={minute}
									selected={(localLiveDate && isValidDate(localLiveDate) && (localLiveMinute === minute)) ? true : false }
								>
									{minute < 10 ? `0${minute}` : minute}
								</option>
								))}
							</select>
							<HoursDiv><small>Tuo orario locale(!)</small></HoursDiv>
							</Row></Col> */}

						</Row>

						{/* <Row>
							<InfoLabel>Senza ora:</InfoLabel>

							<CheckboxInput
								label={"Senza ora"}
								isChecked={(dateIsWithoutTime === true)}
								onChange={(post) => handleOnChangeDateIsWithoutTime(post.valueOf())}
								inputName='DIWT'
							/>
						</Row> */}

						<Row>
							<InfoLabel>Immagine:</InfoLabel>

							<ProductImageContainer>
								<ProductImage
									src={feedpost.image?.url ? feedpost.image.url : ""}
									onMouseOver={onImageOver}
									onMouseOut={onImageOut}
									onClick={() => onImageClick(false)}
								/>

								<ImageEditIconOverlay style={{ opacity: imageIsClickable ? 1 : 0 }}>
									<EditIconSVG />
								</ImageEditIconOverlay>
								<ImageName>{feedpost.image?.fileName ? feedpost.image.fileName : null}</ImageName>
							</ProductImageContainer>
							{!feedpost.image?.url &&
								<PicButton onClick={() => onImageClick(true)}>Carica</PicButton>
							}
							{feedpost.image?.url &&
								false && <PicButton onClick={onRemoveImageClick}>Rimuovi</PicButton>
							}

							<ProductImageNewFile
								ref={productImageNewFileInput}
								onChange={handleSingleFilePictureChange}
								accept="image/*"
							/>
						</Row>

						<Row>
							<InfoLabel>PDF:</InfoLabel>

							<ProductPDFContainer>
								<ProductPDF
									// onMouseOver={onPDFOver}
									// onMouseOut={onPDFOut}
									onClick={() => onPDFClick(true)}
								>
									{feedpost.pdf?.url ? <PDFIconSVG /> : null}
								</ProductPDF>
								<PDFName>{feedpost.pdf?.fileName ? feedpost.pdf.fileName : null}</PDFName>
								{/* <PDFEditIconOverlay style={{ opacity: PDFIsClickable ? 1 : 0 }}>
									<EditIconSVG />
								</PDFEditIconOverlay> */}
							</ProductPDFContainer>
							{!feedpost.pdf?.url &&
								<PDFButton onClick={() => onPDFClick(true)}>Carica</PDFButton>
							}
							{feedpost.pdf?.url &&
								<PDFButton onClick={onRemovePDFClick}>Rimuovi</PDFButton>
							}

							<ProductPDFNewFile
								ref={productPdfNewFileInput}
								onChange={handleSingleFilePDFChange}
								accept=".pdf"
							/>
						</Row>
					</Col>
				</Row>
				<Row>
					<button onClick={saveFeedPost} disabled={!hasChanged || feedpost.deleted} style={{marginRight: '10px'}}>SAVE</button>

					{feedpost.status !== "PUBLISHED" && <button onClick={publishPost} disabled={hasChanged || feedpost.deleted}>PUBBLICA</button>}
					{feedpost.status === "PUBLISHED" && "POST GIA' PUBBLICATO"}
					{/* {feedpost.status === "PUBLISHED" && !feedpost.deleted && <button onClick={deletePost} disabled={hasChanged}>CANCELLA</button>} */}
					{feedpost.deleted && <>&nbsp;&nbsp;| POST CANCELLATO</>}
				</Row>
			</BaseContentCard>
			)}
		</Page>
	);
};

export default FeedPostDetailPage;
